import styled from "styled-components"
import DesignTokens from "./designTokens"

const Label = styled.p`
  color: ${props => props.color || DesignTokens.colors.secondary[500]};
  font-size: ${DesignTokens.fontSizes[2]};
  letter-spacing: 0.2rem;
  margin-bottom: ${DesignTokens.spacing[2]};
  text-transform: uppercase;
  font-weight: 400;
`

export default Label