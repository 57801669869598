import styled from "styled-components"

const Container = styled.div`
  @media screen and (min-width: 769px) {
    margin: 0 auto;
    max-width: 1124px;
  }
`

export default Container
