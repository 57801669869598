import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Container from "./container"
import logo from "../images/formenti-logo.svg"
import { ItemsList } from "../components/utils"
import { Send, Phone } from "react-feather"

const Footer = () => {
  const privacy = {
    it: "https://www.iubenda.com/privacy-policy/65977397",
  }
  const cookie = {
    it: "https://www.iubenda.com/privacy-policy/65977397/cookie-policy",
  }

  const contact = useStaticQuery(graphql`
    query {
      allDatoCmsContactMode {
        nodes {
          id
          email
          phone
          additionalInfo
        }
      }
    }
  `)
  const contactDetails = contact.allDatoCmsContactMode.nodes[0]

  return (
    <Wrapper>
      <CustomContainer>
        <ContactDetails>
          <img src={logo} alt="Carpenteria Formenti Logo" />
          <ItemsList>
            <li>
              <Send size={20} />
              <a
                href={`mailto:` + contactDetails.email}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contactDetails.email}
              </a>
            </li>
            <li>
              <Phone size={20} />
              <a
                href={`tel:` + contactDetails.phone}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contactDetails.phone}
              </a>
            </li>
          </ItemsList>
          <div
            dangerouslySetInnerHTML={{ __html: contactDetails.additionalInfo }}
          />
        </ContactDetails>
        <div>
          <Link to="/processo-e-lavorazioni/">
            <h4>Processo e lavorazioni</h4>
          </Link>
          <ul>
            <li>Taglio e piegatura</li>
            <li>Assiematura</li>
            <li>Saldatura certificata</li>
            <li>Controllo qualità</li>
            <li>Lavorazioni meccaniche</li>
            <li>Trattamenti termici</li>
            <li>Trattamenti superficiali</li>
          </ul>
        </div>
        <div>
          <Link to="/soluzioni-applicative/">
            <h4>Soluzioni applicative</h4>
          </Link>
          <ul>
            <li>Produzione carta e cartone</li>
            <li>Presse automotive</li>
            <li>Presse industriali</li>
            <li>Lavorazione caffe</li>
            <li>Macchine utensili</li>
            <li>Produzioni tubolari</li>
            <li>Linee di spianatura lamiera</li>
          </ul>
        </div>
        <div>
          <Link to="/parco-macchine/">
            <h4>Parco macchine</h4>
          </Link>
          <Link to="/lavora-con-noi/">
            <h4>Lavora con noi</h4>
          </Link>
          <Link to="/contatti/">
            <h4>Contatti</h4>
          </Link>
        </div>
      </CustomContainer>
      <CustomContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <a
            href={privacy.it}
            target="_blank"
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Privacy Policy"
            style={{ marginRight: "12px" }}
          >
            Privacy Policy
          </a>
          <a
            href={cookie.it}
            target="_blank"
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Cookie Policy"
            sx={{ color: "light", fontSize: 0 }}
          >
            Cookie Policy
          </a>
          <a
            href="https://www.datocms-assets.com/24799/1663663588-formenti-informativa-clientifornitori.pdf"
            target="_blank"
            className="iubenda-nostyle no-brand iubenda-embed"
            title="Cookie Policy"
            sx={{ color: "light", fontSize: 0 }}
          >
            Informativa Clienti/Fornitori
          </a>
        </div>
      </CustomContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${DesignTokens.colors.tertiary[500]};
  padding: ${DesignTokens.spacing[9]} ${DesignTokens.spacing[5]};
  @media screen and (min-width: 769px) {
    padding: ${DesignTokens.spacing[9]} 0;
  }
`

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${DesignTokens.spacing[6]};
  @media screen and (min-width: 769px) {
    margin-bottom: 0;
  }
  img {
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  ul {
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: ${DesignTokens.spacing[3]};
    }
  }
  p {
    margin-bottom: ${DesignTokens.spacing[2]};
  }
`

const CustomContainer = styled(Container)`
  color: ${DesignTokens.colors.white};
  display: flex;
  flex-direction: column;
  a {
    color: ${DesignTokens.colors.white};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h4 {
    font-size: ${DesignTokens.fontSizes[5]};
    margin-bottom: ${DesignTokens.spacing[6]};
  }
  @media screen and (min-width: 769px) {
    flex-direction: row;
    & > div {
      margin-right: 4%;
      width: calc(88% / 4);
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export default Footer
