import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Container from "./container"
import Heading from "./heading"

const Header = ({ title, image }) => (
  <Wrapper image={image}>
    <Container>
      <Heading as="h1" dangerouslySetInnerHTML={{ __html: title }} />
    </Container>
  </Wrapper>
)
const Wrapper = styled.div`
  padding: 6rem ${DesignTokens.spacing[5]};
  background: ${DesignTokens.colors.black};
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  color: ${DesignTokens.colors.white};
  text-transform: uppercase;
  @media screen and (min-width: 769px) {
    padding: 12rem ${DesignTokens.spacing[9]};
  }
  h1 {
    color: ${DesignTokens.colors.secondary[500]};
  }
`

export default Header
