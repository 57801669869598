import React from "react"
import { graphql } from "gatsby"
import { useMediaQuery } from "react-responsive"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import ContactForm from "../components/contactForm"
import Container from "../components/container"
import ContactDetails from "../components/contactDetails"
import Footer from "../components/footer"

const ContactPage = ({ data: { page } }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 })
  return (
    <Layout>
      <SEO title={page.title} />
      <Header
        title={page.title}
        image={page.heroImage ? page.heroImage.url : null}
      />
      {isDesktop ? (
        <Wrapper>
          <CustomContainer>
            <ContactForm />
            <ContactDetails title={page.subtitle} contact={page.contactMode} />
          </CustomContainer>
        </Wrapper>
      ) : (
        <MobileCustomContainer>
          <ContactDetails title={page.subtitle} contact={page.contactMode} />
          <ContactForm />
        </MobileCustomContainer>
      )}
      <Footer />
    </Layout>
  )
}

const Wrapper = styled.div`
  padding: ${DesignTokens.spacing[5]};
  @media screen and (min-width: 769px) {
    background: linear-gradient(
      90deg,
      ${DesignTokens.colors.secondary[500]} 50%,
      ${DesignTokens.colors.primary[500]} 50%
    );
  }
`

const MobileCustomContainer = styled.div`
  & > div {
      padding: ${DesignTokens.spacing[4]};
    &:first-child {
      background: ${DesignTokens.colors.primary[500]};
    }
  }
`

const CustomContainer = styled(Container)`
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    & > div {
      &:first-child {
        padding: ${DesignTokens.spacing[8]} ${DesignTokens.spacing[8]}
          ${DesignTokens.spacing[8]} 0;
      }
      &:last-child {
        padding: ${DesignTokens.spacing[8]} 0 ${DesignTokens.spacing[8]}
          ${DesignTokens.spacing[8]};
      }
      width: 50%;
    }
  }
`

export default ContactPage

export const query = graphql`
  query ContactPageQuery {
    page: datoCmsContactPage {
      title
      subtitle
      heroImage {
        url
      }
      contactMode {
        email
        phone
        additionalInfo
      }
    }
  }
`
