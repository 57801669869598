import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Label from "../components/label"
import Heading from "../components/heading"
import { ItemsList } from "../components/utils"
import { Phone, Send } from "react-feather"

const ContactDetails = ({ contact, title }) => (
  <Wrapper>
    <Label>Carpenteria Formenti</Label>
    <Heading as="h2" color={DesignTokens.colors.secondary[500]} downcase>
      {title}
    </Heading>
    <ItemsList>
      <li>
        <Send size={20} />
        <a href={`mailto:` + contact.email} target="_blank" rel="noopener noreferrer">
          {contact.email}
        </a>
      </li>
      <li>
        <Phone size={20} />
        <a href={`tel:` + contact.phone} target="_blank" rel="noopener noreferrer">
          {contact.phone}
        </a>
      </li>
    </ItemsList>
    <AdditionalInformation dangerouslySetInnerHTML={{__html:contact.additionalInfo}} />
  </Wrapper>
)

const Wrapper = styled.div`
  color: ${DesignTokens.colors.white};
  font-size: ${DesignTokens.fontSizes[4]};
  h2 {
    margin-bottom: ${DesignTokens.spacing[8]};
  }
  ul {
    margin-bottom: ${DesignTokens.spacing[8]};
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: ${DesignTokens.spacing[3]};
    }
  }
  a {
    color: ${DesignTokens.colors.white};
  }
`

const AdditionalInformation = styled.div`
  p {
    margin-bottom: ${DesignTokens.spacing[2]};
  }
`

export default ContactDetails
